var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Entête de page *")
                            ]),
                            _c("p", { staticClass: "card-title-desc" }, [
                              _vm._v(" Cette partie sera affichée comme "),
                              _c("b", [_vm._v("entête de page")]),
                              _vm._v(" sur tous les rapports. ")
                            ]),
                            _c("ckeditor", {
                              attrs: { editor: _vm.editor },
                              model: {
                                value:
                                  _vm.reportsSettingsForm.report_paper_head,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.reportsSettingsForm,
                                    "report_paper_head",
                                    $$v
                                  )
                                },
                                expression:
                                  "reportsSettingsForm.report_paper_head"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Pied de page *")
                            ]),
                            _c("p", { staticClass: "card-title-desc" }, [
                              _vm._v(" Cette partie sera affichée comme "),
                              _c("b", [_vm._v("pied de page")]),
                              _vm._v(" sur tous les rapports. ")
                            ]),
                            _c("ckeditor", {
                              attrs: { editor: _vm.editor },
                              model: {
                                value:
                                  _vm.reportsSettingsForm.report_paper_foot,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.reportsSettingsForm,
                                    "report_paper_foot",
                                    $$v
                                  )
                                },
                                expression:
                                  "reportsSettingsForm.report_paper_foot"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          disabled: _vm.$v.reportsSettingsForm.$invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }